<template>
  <Dialog
    :visible="visible"
    :closeOnEscape="true"
    :closable="true"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <DataTable
      class="p-datatable-sm"
      :paginator="true"
      :rows="5"
      stripedRows
      :value="historico"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
      responsiveLayout="scroll">
      <template #header>Histórico</template>
      <template #empty> Nenhum histórico encontrado. </template>
      <Column header="Cadastrado Em">
        <template #body="{ data }">
          {{ data.cadastradoEm | formatarData }}
        </template>
      </Column>
      <Column header="Alterado Em">
        <template #body="{ data }">
          {{ data.alteradoEm | formatarData }}
        </template>
      </Column>
      <Column field="status.descricao" header="Situação" />
      <Column field="nome" header="Usuário" />
      <Column field="prazoRestante" header="Prazo Restante" />
      <Column field="prazoTotal" header="Prazo Total" />
      <Column field="mesReferencia" header="Mês Referência" />
      <Column field="anoReferencia" header="Ano Referência" />
      <Column header="Valor">
        <template #body="{ data }">
          {{ data.valorParcela | formatarValor }}
        </template>
      </Column>
      <Column header="Obervacao">
        <template #body="{ data }">
          {{ data.observacoes ? data.observacoes : '-' }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>
<script>
export default {
  props: {
    visible: { type: Boolean, required: true },
    historico: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {},

    onHide() {},
  },
}
</script>
