export default class OperacaoMovimentoExtraService {
  constructor(http) {
    this._http = http
  }

  async incluir(data) {
    return await this._http.post('/api/operacao-movimento/incluir', data)
  }

  async getOperacaoMovimentoExtraById(id) {
    const { data } = await this._http.get(`/api/operacao-movimento/${id}`)
    return data
  }

  async getLastOperacaoByConsignacao(id) {
    const { data } = await this._http.get(
      `/api/operacao-movimento/last-consignacao/${id}`,
    )
    return data
  }
}
